import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { APIResponse } from '@flowaccount/core/common'
import { GrowthBookService } from '@flowaccount/landing'
import { UserAnalyticCollector, UserAnalyticDataEvent, UserAnalyticEvents } from '../models/event-collector.model';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service'

@Injectable({
  providedIn: 'root',
})
export class EventTrackerService {
  private readonly baseUrl = environment.eventTrackerEndpointUrl ?? ''
  private readonly endpointGenerateToken = environment.eventTrackerEndpointPaths.csrfGenerator ?? ''
  private readonly endpointCollection = environment.eventTrackerEndpointPaths.collect ?? ''
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private growthBookService: GrowthBookService,
  ) {}

  getCsrfToken() {
    const csrfCookieToken = this.cookieService.get('XSRF-TOKEN')

    if (!csrfCookieToken) {
      const endpoint = `${this.baseUrl}${this.endpointGenerateToken}`
      return this.http.get<APIResponse<any>>(endpoint, { withCredentials: true }).subscribe()
    }
  }

  send<T>(data: UserAnalyticCollector<T>) {
    const endpoint = `${this.baseUrl}${this.endpointCollection}`
    return this.http.post<APIResponse<any>>(endpoint, data).subscribe()
  }
}
